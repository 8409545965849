/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { resolveImage, resolveCB, resolveAboveCta } from "@dataResolvers";
import { Page as PageTemplate } from "@pageTemplates";
import QueryContainer from "@templates/QueryContainer";

export const query = graphql`
  query LandingPageQuery($uri: [String], $siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "landingPages", uri: $uri, siteId: $siteId) {
        title
        ... on Craft_landingPages_landingPages_Entry {
          #Hero
          heroTitle
          entrySubtitle
          hideHeroTitle
          #Intro
          intro
          #Sections
          sections {
            ...SectionFragment
          }
          #Above Footer CTAs
          aboveFooterCtaImageBlocks {
            ...AboveFooterFragment
          }
          #Meta
          metaDescription
          metaFeaturedImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  if (!entry) return null;
  const {
    title,
    heroTitle,
    entrySubtitle,
    intro,
    sections,
    aboveFooterCtaImageBlocks,
    metaFeaturedImage,
    metaDescription,
    hideHeroTitle,
  } = entry;

  return {
    title,
    hero: {
      title: heroTitle || title,
      subtitle: entrySubtitle,
      image: resolveImage(metaFeaturedImage),
      hideHeroTitle,
    },
    intro: {
      copy: intro,
    },
    sections: resolveCB(sections),
    cards: resolveAboveCta(aboveFooterCtaImageBlocks),
    meta: {
      title,
      metaFeaturedImage: resolveImage(metaFeaturedImage),
      metaDescription,
    },
  };
};

const Page = ({ data, pageContext }) => (
  <QueryContainer
    data={data}
    pageContext={pageContext}
    PageTemplate={PageTemplate}
    dataResolver={dataResolver}
  />
);

export default Page;
